<template>
  <page-layout ref="layout">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item text="Dev" />
      <b-breadcrumb-item text="Export" active />
    </template>

    <template #actions>
      <b-button variant="outline-primary" size="sm" @click="exportAll()">
        <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" class="mr-50"/> Export All
      </b-button>
    </template>

    <template #content>
      <b-card-group columns>
        <b-card v-for="(type, index) in types.filter(t => t.state.enabled)" :key="index">
          <b-card-title class="text-capitalize d-flex justify-content-between align-items-center">
            {{ type.name }}
            <b-button size="sm" variant="link" @click="type.state.showFields = !type.state.showFields">Fields ({{ type.fields.length }})</b-button>
          </b-card-title>
          <b-collapse v-model="type.state.showFields">
            <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-export-fields mb-2">
              <b-form-group>
                <b-form-checkbox v-for="field in type.fields" :key="field.name" v-model="field.selected" switch size="sm">
                  {{ field.name }}
                  <span v-if="field.description" class="font-weight-bold" :class="getDescriptionClass(field.description)">({{ field.description }})</span>
                </b-form-checkbox>
              </b-form-group>
            </vue-perfect-scrollbar>
          </b-collapse>
          <div>
            <b-button block variant="primary" :disabled="type.state.loading" @click="exportData(type)">Export</b-button>
          </div>
        </b-card>
        <b-card title="Cognito Users">
          <b-button block variant="" :disabled="cognitoUsers.loading" @click="exportCognitoUsers()">Export</b-button>
        </b-card>
        <b-card title="Cognito Group Users">
          <b-button block variant="" :disabled="cognitoGroupUsers.loading" @click="exportCognitoGroupUsers()">Export</b-button>
        </b-card>
      </b-card-group>
    </template>
  </page-layout>
</template>

<script>
import {API, graphqlOperation} from 'aws-amplify';
import {
  listApplications, listDistricts,
  listDocumentCategories, listDocuments,
  listEnsembleInstruments, listEnsembles,
  listFestivals, listForms, listInstruments,
  listInvoices, listPayments,
  listSchools, listSelections, listSettings, listStudents,
  listTicketReplies, listTickets,
  listUsers, listUserSchools,
  listZones
} from './queries/export';
import {
  adminListGroups,
  adminListUsers,
  adminListUsersInGroup
} from '@/scripts/aws';
import _ from 'lodash';
import vSelect from 'vue-select';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import PageLayout from '@/components/PageLayout.vue';

export default {
  name: 'Export',
  components: {
    PageLayout,
    vSelect,
    VuePerfectScrollbar
  },
  data: () => ({
    types: [
      {
        name: 'application',
        query: listApplications,
        operation: 'listApplications',
        fields: [
          { name: 'id', description: 'Application PK', selected: true },
          { name: 'teacherID', description: 'User FK', selected: true },
          { name: 'studentApplicationsId', description: 'Student FK', selected: true },
          { name: 'applicationFestivalId', description: 'Festival FK', selected: true },
          { name: 'applicationFormId', description: 'Form FK', selected: true },
          { name: 'applicationInstrumentId', description: 'Instrument FK', selected: true },
          { name: 'applicationSelectionId', description: 'Selection FK', selected: true },
          { name: 'questions.lastYear.attended', selected: true },
          { name: 'questions.lastYear.chair', selected: true },
          { name: 'questions.lastYear.group', selected: true },
          { name: 'questions.lastYear.part', selected: true },
          { name: 'questions.experience.instrument.doubles', selected: true },
          { name: 'questions.experience.instrument.years', selected: true },
          { name: 'questions.experience.jazzEnsemble', selected: true },
          { name: 'questions.experience.orchestral', selected: true },
          { name: 'questions.experience.vocalJazzAccompaniment', selected: true },
          { name: 'questions.moreThanOne.response', selected: true },
          { name: 'questions.moreThanOne.instruments', selected: true },
          { name: 'questions.organizations.school', selected: true },
          { name: 'questions.organizations.other', selected: true },
          { name: 'questions.previousSoloRating.ninth.level', selected: true },
          { name: 'questions.previousSoloRating.ninth.grade', selected: true },
          { name: 'questions.previousSoloRating.tenth.level', selected: true },
          { name: 'questions.previousSoloRating.tenth.grade', selected: true },
          { name: 'grading.tone', selected: true },
          { name: 'grading.rhythmicSense', selected: true },
          { name: 'grading.leadership', selected: true },
          { name: 'grading.maturity', selected: true },
          { name: 'grading.articulation', selected: true },
          { name: 'grading.intonation', selected: true },
          { name: 'grading.citizenship', selected: true },
          { name: 'grading.diction', selected: true },
          { name: 'grading.flexibility', selected: true },
          { name: 'grading.generalMusicianship', selected: true },
          { name: 'grading.attitude', selected: true },
          { name: 'grading.technique', selected: true },
          { name: 'grading.sightReading', selected: true },
          { name: 'grading.responsibility', selected: true },
          { name: 'recommendation', selected: true },
          { name: 'comments', selected: true },
          { name: 'ranking.score', selected: true },
          { name: 'ranking.local', selected: true },
          { name: 'ranking.state', selected: true },
          { name: 'verified', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'district',
        query: listDistricts,
        operation: 'listDistricts',
        fields: [
          { name: 'id', description: 'District PK', selected: true },
          { name: 'districtZoneId', description: 'Zone FK', selected: true },
          { name: 'beds', selected: true },
          { name: 'slug', selected: true },
          { name: 'name.legal', selected: true },
          { name: 'name.popular', selected: true },
          { name: 'avatar.size', selected: true },
          { name: 'avatar.name', selected: true },
          { name: 'avatar.src', selected: true },
          { name: 'address.line1', selected: true },
          { name: 'address.line2', selected: true },
          { name: 'address.city', selected: true },
          { name: 'address.county', selected: true },
          { name: 'address.state', selected: true },
          { name: 'address.zip', selected: true },
          { name: 'staff.type', selected: true },
          { name: 'staff.title', selected: true },
          { name: 'state.enabled', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'documentCategory',
        query: listDocumentCategories,
        operation: 'listDocumentCategories',
        fields: [
          { name: 'id', description: 'Document Category PK', selected: true },
          { name: 'slug', selected: true },
          { name: 'title', selected: true },
          { name: 'description', selected: true },
          { name: 'icon', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'document',
        query: listDocuments,
        operation: 'listDocuments',
        fields: [
          { name: 'id', selected: true },
          { name: 'slug', selected: true },
          { name: 'categoryID', selected: true },
          { name: 'title', selected: true },
          { name: 'description', selected: true },
          { name: 'content', selected: true },
          { name: 'isPublished', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'ensemble',
        query: listEnsembles,
        operation: 'listEnsembles',
        fields: [
          { name: 'id', description: 'Ensemble PK', selected: true },
          { name: 'slug', selected: true },
          { name: 'name', selected: true },
          { name: 'code', selected: true },
          { name: 'parts', selected: true },
          { name: 'hasParts', selected: true },
          { name: 'hasAlternates', selected: true },
          { name: 'state.enabled', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'ensembleInstrument',
        query: listEnsembleInstruments,
        operation: 'listEnsembleInstruments',
        fields: [
          { name: 'id', description: 'Ensemble Instrument PK', selected: true },
          { name: 'ensembleID', description: 'Ensemble FK', selected: true },
          { name: 'instrumentID', description: 'Instrument FK', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'festival',
        query: listFestivals,
        operation: 'listFestivals',
        fields: [
          { name: 'id', description: 'Festival PK', selected: true },
          { name: 'festivalZoneId', description: 'Zone FK', selected: true },
          { name: 'slug', selected: true },
          { name: 'name', selected: true },
          { name: 'site', selected: true },
          { name: 'address.line1', selected: true },
          { name: 'address.line2', selected: true },
          { name: 'address.city', selected: true },
          { name: 'address.county', selected: true },
          { name: 'address.state', selected: true },
          { name: 'address.zip', selected: true },
          { name: 'state.enabled', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },

        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'form',
        query: listForms,
        operation: 'listForms',
        fields: [
          { name: 'id', description: 'Form PK', selected: true },
          { name: 'slug', selected: true },
          { name: 'name', selected: true },
          { name: 'type', selected: true },
          { name: 'state.enabled', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'instrument',
        query: listInstruments,
        operation: 'listInstruments',
        fields: [
          { name: 'id', description: 'Instrument PK', selected: true },
          { name: 'formInstrumentsId', description: 'Form FK', selected: true },
          { name: 'slug', selected: true },
          { name: 'name', selected: true },
          { name: 'order', selected: true },
          { name: 'state.enabled', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'invoice',
        query: listInvoices,
        operation: 'listInvoices',
        fields: [
          { name: 'id', description: 'Invoice PK', selected: true },
          { name: 'invoiceStudentId', description: 'Student FK', selected: true },
          { name: 'number', selected: true },
          { name: 'amount', selected: true },
          { name: 'dueAt', selected: true },
          { name: 'memo', selected: true },
          { name: 'duplicates', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'payment',
        query: listPayments,
        operation: 'listPayments',
        fields: [
          { name: 'id', description: 'Payment PK', selected: true },
          { name: 'invoicePaymentsId', description: 'Invoice FK', selected: true },
          { name: 'type', selected: true },
          { name: 'amount', selected: true },
          { name: 'check', selected: true },
          { name: 'received', selected: true },
          { name: 'returned', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'school',
        query: listSchools,
        operation: 'listSchools',
        fields: [
          { name: 'id', description: 'School PK', selected: true },
          { name: 'districtSchoolsId', description: 'District FK', selected: true },
          { name: 'schoolZoneId', description: 'Zone FK', selected: true },
          { name: 'beds', selected: true },
          { name: 'slug', selected: true },
          { name: 'name.legal', selected: true },
          { name: 'name.popular', selected: true },
          { name: 'avatar.size', selected: true },
          { name: 'avatar.name', selected: true },
          { name: 'avatar.src', selected: true },
          { name: 'address.line1', selected: true },
          { name: 'address.line2', selected: true },
          { name: 'address.city', selected: true },
          { name: 'address.county', selected: true },
          { name: 'address.state', selected: true },
          { name: 'address.zip', selected: true },
          { name: 'staff.type', selected: true },
          { name: 'staff.title', selected: true },
          { name: 'state.enabled', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'selection',
        query: listSelections,
        operation: 'listSelections',
        fields: [
          { name: 'id', description: 'Selection PK', selected: true },
          { name: 'selectionApplicationId', description: 'Application FK', selected: true },
          { name: 'selectionEnsembleId', description: 'Ensemble FK', selected: true },
          { name: 'part', selected: true },
          { name: 'ranking.state', selected: true },
          { name: 'accepted', selected: true },
          { name: 'state.order', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'setting',
        query: listSettings,
        operation: 'listSettings',
        fields: [
          { name: 'key', description: 'Setting PK', selected: true },
          { name: 'value', selected: true },
          { name: 'description', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'student',
        query: listStudents,
        operation: 'listStudents',
        fields: [
          { name: 'id', description: 'Student PK', selected: true },
          { name: 'schoolID', description: 'School FK', selected: true },
          { name: 'studentInvoiceId', description: 'Invoice FK', selected: true },
          { name: 'busStudentsId', description: 'Bus FK', selected: true },
          { name: 'name.first', selected: true },
          { name: 'name.last', selected: true },
          { name: 'name.preferred', selected: true },
          { name: 'dob', selected: true },
          { name: 'sex', selected: true },
          { name: 'gender', selected: true },
          { name: 'address.line1', selected: true },
          { name: 'address.line2', selected: true },
          { name: 'address.city', selected: true },
          { name: 'address.county', selected: true },
          { name: 'address.state', selected: true },
          { name: 'address.zip', selected: true },
          { name: 'phone.type', selected: true },
          { name: 'phone.number', selected: true },
          { name: 'phone.ext', selected: true },
          { name: 'email.type', selected: true },
          { name: 'email.address', selected: true },
          { name: 'grade', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'ticket',
        query: listTickets,
        operation: 'listTickets',
        fields: [
          { name: 'id', description: 'Ticket PK', selected: true },
          { name: 'userID', description: 'User FK', selected: true },
          { name: 'title', selected: true },
          { name: 'text', selected: true },
          { name: 'status', selected: true },
          { name: 'labels', selected: true },
          { name: 'state.locked', selected: true },
          { name: 'state.hidden', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'ticketReply',
        query: listTicketReplies,
        operation: 'listTicketReplies',
        fields: [
          { name: 'id', description: 'Ticket Reply PK', selected: true },
          { name: 'ticketRepliesId', description: 'Ticket FK', selected: true },
          { name: 'ticketReplyUserId', description: 'User FK', selected: true },
          { name: 'text', selected: true },
          { name: 'state.locked', selected: true },
          { name: 'state.hidden', selected: true },
          { name: 'isAutomatedReply', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'user',
        query: listUsers,
        operation: 'listUsers',
        fields: [
          { name: 'id', description: 'User PK', selected: true },
          { name: 'districtUsersId', description: 'District FK', selected: true },
          { name: 'ensembleIds', description: 'Ensemble FKs (Loose)', selected: true },
          { name: 'instrumentIds', description: 'Instrument FKs (Loose)', selected: true },
          { name: 'zoneIds', description: 'Zone FKs (Loose)', selected: true },
          { name: 'username', selected: true },
          { name: 'name.first', selected: true },
          { name: 'name.last', selected: true },
          { name: 'name.preferred', selected: true },
          { name: 'address.line1', selected: true },
          { name: 'address.line2', selected: true },
          { name: 'address.city', selected: true },
          { name: 'address.county', selected: true },
          { name: 'address.state', selected: true },
          { name: 'address.zip', selected: true },
          { name: 'avatar.size', selected: true },
          { name: 'avatar.name', selected: true },
          { name: 'avatar.src', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },

        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'userSchool',
        query: listUserSchools,
        operation: 'listUserSchools',
        fields: [
          { name: 'id', description: 'User School PK', selected: true },
          { name: 'schoolID', description: 'School FK', selected: true },
          { name: 'userID', description: 'User FK', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      },
      {
        name: 'zone',
        query: listZones,
        operation: 'listZones',
        fields: [
          { name: 'id', description: 'Zone PK', selected: true },
          { name: 'name', selected: true },
          { name: 'counties', selected: true },
          { name: 'state.enabled', selected: true },
          { name: 'createdAt', selected: true },
          { name: 'updatedAt', selected: true },
        ],
        state: {
          enabled: true,
          loading: false,
          showFields: false
        }
      }
    ],
    cognitoUsers: { loading: false },
    cognitoGroupUsers: { loading: false },
  }),
  async mounted() {
    await this.$nextTick(() => {
      setTimeout( () => { this.$refs.layout.state.loading = false }, 500);
    })
  },
  methods: {
    exportToJSON(type, data) {
      /** Build File && Download **/
      const blob = new Blob([JSON.stringify(data)], { type: 'data:text/plain;charset=utf-8;' });
      const downloadable = document.createElement('a');
      downloadable.href = URL.createObjectURL(blob);
      downloadable.download = `nyssma-${type.name}-${new Date().toISOString()}.json`;
      downloadable.click();
      setTimeout(() => URL.revokeObjectURL( downloadable.href ), 60000 );
    },

    exportToCSV(type, items) {
      let csv = ''
      const rows = []

      /** Add Headers to Rows **/
      rows.push(type.fields.map(field => `"${field.name}"`).join(', '))

      /** Add Data to Rows **/
      items.forEach(item => {
        const row = []
        type.fields.forEach(field => {
          let value = _.get(item, field.name)
          const isWrapped = _.isString(value) || _.isArray(value)

          if(_.isArray(value)) {
            if(!_.isString(value[0])) {
              value = JSON.stringify(value)
            }
          }

          row.push( isWrapped ? `"${value}"` : value)
        })
        rows.push(row.join(', '))
      })

      /** Build CSV **/
      rows.forEach(row => {
        csv = `${csv + row}\n`
      })

      /** Build File && Download **/
      const blob = new Blob([csv], { type: 'data:text/csv;charset=utf-8;' });
      const downloadable = document.createElement('a');
      downloadable.href = URL.createObjectURL( blob );
      downloadable.download = `nyssma-${type.name}-${new Date().toISOString()}.csv`;
      downloadable.click();
      setTimeout(() => URL.revokeObjectURL( downloadable.href ), 60000 );
    },

    async exportData(type) {
      type.state.loading = true
      const items = []
      let nextToken = null;
      do {
        // eslint-disable-next-line no-await-in-loop
        const response = await API.graphql(graphqlOperation(type.query, { limit: 1000, nextToken: nextToken }));
        items.push(...response.data[type.operation].items.map(item => type.fields.reduce((result, field) => {
          if (field.selected) {
            this.assignNested(result, field.name.split('.'), _.get(item, field.name));
          }
          return result;
        }, {})));
        nextToken = response.data[type.operation].nextToken;
      } while (nextToken)

      this.exportToJSON(type, items);
      //this.exportToCSV(type, items)
      type.state.loading = false
    },

    assignNested(obj, keyPath, value) {
      const lastKeyIndex = keyPath.length - 1;
      for (let i = 0; i < lastKeyIndex; ++i) {
        const key = keyPath[i];
        if (!(key in obj)) obj[key] = {};
        obj = obj[key];
      }
      obj[keyPath[lastKeyIndex]] = value;
    },

    async exportAll() {
      await Promise.all(this.types.map(async (type) => {
        if (type.state.enabled) {
          await this.exportData(type);
        }
      }));
      await this.exportApplications()
      await this.exportCognitoUsers()
    },

    /** Util **/
    getDescriptionClass(description) {
      if(description.includes('PK')) {
        return 'text-danger'
      }
      if(description.includes('FK')) {
        return 'text-warning'
      }
      return 'text-muted'
    },

    /** Cognito **/
    async exportCognitoUsers() {
      this.cognitoUsers.loading = true
      try {
        const items = await adminListUsers()
        this.exportToJSON('cognito-users', items);
      }
      catch(e) { console.error(e) }
      this.cognitoUsers.loading = false
    },
    async exportCognitoGroupUsers() {
      this.cognitoGroupUsers.loading = true
      try {
        const cognitoGroupsResponse = await adminListGroups()
        const groups = cognitoGroupsResponse.Groups

        const promises = groups.map(async group => {
          const groupUsers = await adminListUsersInGroup(group.GroupName)
          return { name: group.GroupName, users: groupUsers }
        })
        await Promise.all(promises).then(values => {
          this.exportToJSON('cognito-group-users', values);
        })
      }
      catch(e) { console.error(e) }
      this.cognitoGroupUsers.loading = false
    },
  }
}
</script>

<style scoped>
  ::v-deep {
    .ps-export-fields {
      max-height: 277px;
    }
  }
</style>
